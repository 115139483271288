// UserList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EditUser from './EditUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPencilAlt, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showUserList, setShowUserList] = useState(true); // Initially true to show the list
  const [editingUserId, setEditingUserId] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null); // State to track the user to be deleted
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/api/users/getallusers`);
        setUsers(Array.isArray(response.data) ? response.data : []);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  const handleDelete = async (userId) => {
    try {
      await axios.delete(`${BACKEND_URL}/api/users/${userId}`);
      setUsers(users.filter(user => user._id !== userId));
      setUserToDelete(null);
    } catch (error) {
      console.error('Failed to delete user', error);
    }
  };

  const handleEdit = (userId) => {
    setEditingUserId(userId);
  };

  const handleCloseEdit = () => {
    setEditingUserId(null);
  };

  const handleUserUpdated = (updatedUser) => {
    setUsers(users.map(user => (user._id === updatedUser._id ? updatedUser : user)));
  };

  const openDeleteConfirmation = (userId) => {
    setUserToDelete(userId); // Set the user to delete when clicking delete button
  };

  const closeDeleteConfirmation = () => {
    setUserToDelete(null); // Close the delete confirmation popup
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading users: {error}</p>;

  return (
    <>
    {showUserList && (
      <div className={`user-popup ${showUserList ? 'activeee' : ''}`}>
        <div className="popup-content">
          <h2>User List</h2>
          <ul>
            {users.map((user) => (
              <li key={user._id}>
                <p>Name: {user.name}</p>
                <p>Email: {user.email}</p>
                <div className="btn-container">
                  <button className="edit-btn" onClick={() => handleEdit(user._id)}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </button>
                  <button className="delete-btn" onClick={() => openDeleteConfirmation(user._id)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <button type='button' className='close-btn' onClick={() => setShowUserList(false)}>Close</button>
      </div>
    )}
    {editingUserId && (
      <div className={`user-edit-popup ${editingUserId ? 'activeee' : ''}`}>
          <EditUser
            userId={editingUserId}
            onClose={handleCloseEdit}
            onUserUpdated={handleUserUpdated}
          />
      </div>
    )}
    {userToDelete && (
      <div className={`confirmation-popup ${userToDelete ? 'activeee' : ''}`}>
        <div className="popup-delete-content">
          <div className="exclamation-icon">
            <FontAwesomeIcon icon={faExclamationTriangle} size="3x" color="orange" />
          </div>
          <h2>Heads up!</h2>
          <p>This action cannot be undone. Are you sure you want to delete this user?</p>
          <div className="popup-actions">
            <button className="cancel-btn" onClick={closeDeleteConfirmation}>
              No
            </button>
            <button className="confirm-btn" onClick={() => handleDelete(userToDelete)}>
              Yes, sure!
            </button>
          </div>
        </div>
      </div>
    )}
  </> 
  );
};

export default UserList;
