// LoadingScreen.js
import React from 'react';
import './LoadingScreen.scss'; // Optional: style the loading screen

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <div className="spinner"></div>
      <p>Hang on tight .......</p>
    </div>
  );
};

export default LoadingScreen;
