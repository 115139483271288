import React, { useState, useRef, useEffect, useCallback } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useNavigate } from "react-router-dom";
import "./Home.scss";
import { useDispatch, useSelector } from 'react-redux';
import productService from "../../redux/features/product/productService";
import axios from 'axios';
import { BACKEND_URL, logoutUser } from "../../services/authService";
import logo from "../../assets/AR logo.png";
import { SET_LOGIN } from "../../redux/features/auth/authSlice";
import { selectTelemetryData } from '../../redux/selectors';
import DeviceList from '../../components/deviceList/DeviceList';
import AddDeviceForm from '../../components/deviceList/AddDevice';
import CreateGeofenceForm from '../../components/deviceList/CreateGeofenceForm';
import deliveryVanIconUrl from "../../assets/delivery_van_icon.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faHistory, faMapMarkerAlt, faEnvelope, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import 'leaflet.heat'; 
import LoadingScreen from './LoadingScreen.js'; // Import the Loading Screen

// Integrated CreateUserForm directly within Home.js

const CreateUserForm = ({ deviceDetails, onSubmit, onClose }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    deviceIds: [],
    logics: []
  });
  const [selectAllDevices, setSelectAllDevices] = useState(false);
  const [selectAllLogics, setSelectAllLogics] = useState(false);

  const logicOptions = ["logic1", "logic2", "logic3", "logic4"]; // Define available logics

  // Handle individual device selection
  const handleDeviceSelection = (deviceId) => {
    setFormData(prevFormData => {
      const updatedDeviceIds = prevFormData.deviceIds.includes(deviceId)
        ? prevFormData.deviceIds.filter(id => id !== deviceId)
        : [...prevFormData.deviceIds, deviceId];

      setSelectAllDevices(updatedDeviceIds.length === Object.keys(deviceDetails).length);
      return { ...prevFormData, deviceIds: updatedDeviceIds };
    });
  };

  // Handle select all devices
  const handleSelectAllDevicesChange = () => {
    if (selectAllDevices) {
      setFormData(prevFormData => ({ ...prevFormData, deviceIds: [] }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        deviceIds: Object.keys(deviceDetails)
      }));
    }
    setSelectAllDevices(!selectAllDevices);
  };

  // Handle individual logic selection
  const handleLogicSelection = (logic) => {
    setFormData(prevFormData => {
      const updatedLogics = prevFormData.logics.includes(logic)
        ? prevFormData.logics.filter(l => l !== logic)
        : [...prevFormData.logics, logic];

      setSelectAllLogics(updatedLogics.length === logicOptions.length);
      return { ...prevFormData, logics: updatedLogics };
    });
  };

  // Handle select all logics
  const handleSelectAllLogicsChange = () => {
    if (selectAllLogics) {
      setFormData(prevFormData => ({ ...prevFormData, logics: [] }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        logics: logicOptions
      }));
    }
    setSelectAllLogics(!selectAllLogics);
  };

  // Handle other form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    onSubmit(formData);
  };

  return (
    <div className="form-popup activee">
      <form onSubmit={handleSubmit}>
        <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} required />
        <input type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} required />
        <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
        <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} required />
        <input type="password" name="confirmPassword" placeholder="Confirm Password" value={formData.confirmPassword} onChange={handleChange} required />

        <div className="checkbox-group">
          Devices:
          <label>
            <input
              type="checkbox"
              checked={selectAllDevices}
              onChange={handleSelectAllDevicesChange}
            />
            Select All
          </label>
          {Object.entries(deviceDetails).map(([id, device]) => (
            <label key={id}>
              <input
                type="checkbox"
                value={id}
                checked={formData.deviceIds.includes(id)}
                onChange={() => handleDeviceSelection(id)}
              />
              {device.name}
            </label>
          ))}
        </div>

        <div className="checkbox-group">
          Logics:
          <label>
            <input
              type="checkbox"
              checked={selectAllLogics}
              onChange={handleSelectAllLogicsChange}
            />
            Select All Logics
          </label>
          {logicOptions.map(logic => (
            <label key={logic}>
              <input
                type="checkbox"
                value={logic}
                checked={formData.logics.includes(logic)}
                onChange={() => handleLogicSelection(logic)}
              />
              {logic}
            </label>
          ))}
        </div>

        <button type="submit" className="btn-primary">Create User</button>
        <button type="button" className="close-btn" onClick={onClose}>Close</button>
      </form>
    </div>
  );
};

const { getVehicleTelemetryData } = productService;
const REACT_APP_FLESPI_TOKEN = process.env.REACT_APP_FLESPI_TOKEN;

const Home = () => {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const markersRef = useRef([]);
  const deviceDetailsRef = useRef({}); 
  const heatLayerRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const telemetryData = useSelector(selectTelemetryData);
  const loading = useSelector((state) => state.vehicle.loading);
  const error = useSelector((state) => state.vehicle.error);

  const [vehicleData, setVehicleData] = useState([]);
  const [deviceDetails, setDeviceDetails] = useState({});
  const [selectedLogic, setSelectedLogic] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [showCreateUserForm, setShowCreateUserForm] = useState(false);
  const [showAddDeviceForm, setShowAddDeviceForm] = useState(false);
  const [showUserList, setShowUserList] = useState(false); // State to toggle UserList display
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); 
  const [currentTileLayer, setCurrentTileLayer] = useState(null);
  const [showCreateGeofenceForm, setShowCreateGeofenceForm] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    // If the click is outside the dropdown, close it
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const contextMenu = document.getElementById('contextMenu');
      if (contextMenu && !contextMenu.contains(event.target)) {
        contextMenu.style.display = 'none';
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const logout = async () => {
    await logoutUser();
    await dispatch(SET_LOGIN(false));
    navigate("/");
  };

  const fetchDeviceDetails = useCallback(async (deviceId) => {
    if (deviceDetailsRef.current[deviceId]) return; // Return if details are already fetched

    const maxRetries = 5;
    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    for (let i = 0; i < maxRetries; i++) {
        try {
            const response = await axios.get(`${BACKEND_URL}/api/flespi/gw/devices/${deviceId}`, {
                headers: {
                    'Authorization': `FlespiToken ${REACT_APP_FLESPI_TOKEN}`,
                    'Content-Type': 'application/json',
                },
            });
            console.log(response);
            

            if (response.data && response.data.result && response.data.result.length > 0) {
                const deviceData = response.data.result[0];
                deviceDetailsRef.current[deviceId] = deviceData; // Store in ref
                setDeviceDetails({ ...deviceDetailsRef.current }); // Trigger re-render
                return; // Exit the function if successful
            } else {
                console.warn(`No device data found for device ID: ${deviceId}`);
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 429) {
                    const retryAfter = error.response.headers['retry-after']
                        ? parseInt(error.response.headers['retry-after']) * 1000
                        : (2 ** i) * 1000;
                    console.warn(`Rate limit exceeded. Retrying after ${retryAfter} ms...`);
                    await delay(retryAfter);
                } else {
                    console.error('Error fetching device details:', error);
                    break;
                }
            } else {
                console.error('Error fetching device details:', error);
                break;
            }
        }
    }
}, []);

useEffect(() => {
    const deviceIds = [
        5812973, 5850881, 5819862, 5850580, 5851101, 5870018, 5870272,
        5880168, 5880170, 5881262, 5882831, 5882911, 5886022, 5896298, 
        5896299, 5954456, 5964236, 5980883, 5983450, 5991154, 5992506,
        5992512, 5992514, 5992518, 5992522, 5992523, 5992527, 5992530,
        5992531, 5992533, 6004115, 6004458
    ];

    deviceIds.forEach(deviceId => {
        fetchDeviceDetails(deviceId);
    });
}, [fetchDeviceDetails]);

useEffect(() => {
    const fetchVehicleData = async () => {
        await dispatch(getVehicleTelemetryData());
    };

    fetchVehicleData();
  
    // Set a delay of 20 seconds (20000 milliseconds) before setting dataLoaded to true
    const loadingTimeout = setTimeout(() => {
      setDataLoaded(true);
    }, 20000); // 20 seconds
  
    return () => {
      clearTimeout(loadingTimeout); // Cleanup timeout if the component is unmounted
    };
  }, [dispatch]);

  // Initialize map
  useEffect(() => {
    if (mapRef.current && !mapInstance.current) {
      const initializedMap = L.map(mapRef.current).setView([53.480759, -2.242631], 9);

      const defaultTileLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://arexperts.co.uk/">AR Experts LTD</a>'
      });

      defaultTileLayer.addTo(initializedMap);
      setCurrentTileLayer(defaultTileLayer);

      const switchToLayer = (layerType) => {
        if (currentTileLayer) {
          initializedMap.removeLayer(currentTileLayer);
        }

        let newTileLayer;

        switch (layerType) {
          case 'roadmap':
            newTileLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
              attribution: '&copy; <a href="http://arexperts.co.uk/">AR Experts LTD</a>'
            });
            break;
          case 'hybrid':
            newTileLayer = L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
              attribution: '&copy; <a href="http://arexperts.co.uk/">AR Experts LTD</a>'
            });
            break;
          case 'streetmap':
            newTileLayer = L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
              attribution: '&copy; <a href="http://arexperts.co.uk/">AR Experts LTD</a>'
            });
            break;
          case 'heatmap':
            if (heatLayerRef.current) {
              initializedMap.removeLayer(heatLayerRef.current);
            }

            const heatData = telemetryData.map(vehicle => {
              return [vehicle['position.latitude'], vehicle['position.longitude'], 5];
            });

            heatLayerRef.current = L.heatLayer(heatData, {
              radius: 25,
              blur: 15,
              maxZoom: 17,
            }).addTo(initializedMap);

            break;
          
          default:
            newTileLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
              attribution: '&copy; <a href="http://arexperts.co.uk/">AR Experts LTD</a>'
            });
        }
        
        if (newTileLayer) {
          newTileLayer.addTo(initializedMap);
          setCurrentTileLayer(newTileLayer);
        }
      };

      const removeHeatmap = () => {
        if (heatLayerRef.current) {
          mapInstance.current.removeLayer(heatLayerRef.current);
          heatLayerRef.current = null;
          console.log('Heatmap removed');
        }
      };

      const customControl = L.control({ position: 'bottomleft' });
      customControl.onAdd = function(map) {
        const div = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom custom-bottom-center');
        div.innerHTML = `
          <button class="map-button" id="button1">Roadmap</button>
          <button class="map-button" id="button2">Hybrid</button>
          <button class="map-button" id="button3">StreetMap</button>
          <button class="map-button" id="button4">Heat Map ON</button>
          <button class="map-button" id="button5">Heat Map OFF</button>
        `;
        return div;
      };
      customControl.addTo(initializedMap);

      L.DomEvent.on(L.DomUtil.get('button1'), 'click', () => {
        switchToLayer('roadmap');
      });
      L.DomEvent.on(L.DomUtil.get('button2'), 'click', () => {
        switchToLayer('hybrid');
      });
      L.DomEvent.on(L.DomUtil.get('button3'), 'click', () => {
        switchToLayer('streetmap');
      });
      L.DomEvent.on(L.DomUtil.get('button4'), 'click', () => {
        switchToLayer('heatmap');
      });
      L.DomEvent.on(L.DomUtil.get('button5'), 'click', () => {
        removeHeatmap();
      });

      mapInstance.current = initializedMap;
      console.log('Map initialized');
    }
  }, [currentTileLayer, telemetryData]);

  useEffect(() => {
    if (mapInstance.current) {
      const fetchAllDevices = async () => {
        try {
          let accumulatedVehicleData = [];

          const deviceIds = [
              5812973, 5850881, 5819862, 5850580, 5851101, 5870018, 5870272,
              5880168, 5880170, 5881262, 5882831, 5882911, 5886022, 5896298, 
              5896299, 5954456, 5964236, 5980883, 5983450, 5991154, 5992506,
              5992512, 5992514, 5992518, 5992522, 5992523, 5992527, 5992530,
              5992531, 5992533, 6004115, 6004458
           ];

          for (const deviceId of deviceIds) {
              const response = await axios.get(`${BACKEND_URL}/api/flespi/gw/devices/${deviceId}/telemetry/position,can.vehicle.mileage,can.engine.rpm,can.fuel.level,can.engine.ignition.status,can.handbrake.status,can.vehicle.speed`, {
                  headers: {
                      'Authorization': `FlespiToken ${REACT_APP_FLESPI_TOKEN}`,
                      'Content-Type': 'application/json',
                  },
              });

              if (response.data && response.data.result && response.data.result.length > 0) {
                  const vehicle = response.data.result[0];
                  const telemetry = vehicle.telemetry;
                  const position = telemetry.position ? telemetry.position.value : null;

                  await fetchDeviceDetails(deviceId);

                  const deviceData = deviceDetailsRef.current[deviceId];
                  const deviceName = deviceData?.name || `Device ${deviceId}`;
                  const imei = deviceData?.configuration?.ident;

                  if (position) {
                      const longitude = position.longitude;
                      const latitude = position.latitude;

                      if (typeof latitude === 'number' && typeof longitude === 'number' && !isNaN(latitude) && !isNaN(longitude)) {
                          const popupContent =
                              `<div class="custom-popup">
                                  <h4>${deviceName}</h4>
                              </div>`;

                          const marker = L.marker([latitude, longitude], {
                              icon: L.icon({
                                  iconUrl: deliveryVanIconUrl,
                                  iconSize: [30, 40],
                                  iconAnchor: [15, 40],
                                  popupAnchor: [0, -40],
                              }),
                          }).addTo(mapInstance.current).bindPopup(popupContent);

                          markersRef.current.push(marker);
                      }

                      accumulatedVehicleData.push({
                          longitude,
                          latitude,
                          deviceName,
                          deviceId: vehicle.id,
                          imei,
                          telemetry,
                      });
                  }
              } else {
                  console.warn(`No data found for device ${deviceId}`);
              }
          }

          console.log('All vehicle data:', accumulatedVehicleData);

          setVehicleData(accumulatedVehicleData);
          setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching devices:', error.response?.data || error.message);
        }
      };

      fetchAllDevices();
    }
  }, [fetchDeviceDetails]);

  useEffect(() => {
    console.log('Rendering vehicle data:', vehicleData);
  }, [vehicleData]);
  
  const flyToVehicle = (vehicle) => {
    if (mapInstance.current) {
      const { longitude, latitude } = vehicle;
      mapInstance.current.flyTo([latitude, longitude], 14, { duration: 3 });
    }
    setSelectedVehicle(vehicle);
  };

  const handleLogicSelection = (event) => {
    const logic = event.target.value;
    setSelectedLogic(logic);
    if (selectedVehicle) {
      const { imei } = selectedVehicle;
      switch(logic) {
        case 'logic1':
          navigate(`/logiconedashboard/${imei}`);
          break;
        case 'logic2':
          navigate(`/logictwodashboard/${imei}`);
          break;
        case 'logic3':
          navigate(`/logicthreedashboard/${imei}`);
          break;
        case 'logic4':
        default:
          navigate(`/dashboard/${imei}`);
          break;
      }
    }
  };

  const handleCreateUserFormSubmit = async (formData) => {
    if (formData.password !== formData.confirmPassword) {
        alert("Passwords do not match!");
        return;
    }
    try {
        const response = await axios.post(`${BACKEND_URL}/api/users/registerWithDevice`, formData);
        if (response.status === 200 || response.status === 201) {
            alert("User created and confirmation email sent successfully!");
            setShowCreateUserForm(false);
        } else {
            alert(`Failed to create user: ${response.data.message}`);
        }
    } catch (error) {
        console.error("Error creating user:", error);
        alert(`Failed to create user: ${error.response ? error.response.data.message : error.message}`);
    }
};


  const handleCreateGeofenceSubmit = async (formData) => {
    try {
      const response = await axios.post(`${BACKEND_URL}/api/geofence/create`, formData);
      if (response.status === 201 || response.status === 200) {
        alert("Geofence created successfully!");
        setShowCreateGeofenceForm(false);
      } else {
        alert(`Failed to create geofence: ${response.data.message}`);
      }
    } catch (error) {
      console.error("Error creating geofence:", error);
      alert(`Failed to create geofence: ${error.response ? error.response.data.message : error.message}`);
    }
  };

  const handleNavigateToHistory = () => {
    navigate('/data-and-history', { state: { from: '/devices' } });
  };

  const handleNavigateToGeofencing = () => {
    navigate('/geofencing', { state: { from: '/devices' } });
  };

  const handleNavigateToContact = () => {
    navigate('/contact-us', { state: { from: '/devices' } });
  };

  return (
    <div className="home">
      <nav className="navbar">
        <div className="container --flex-between">
          <div className="navbar-brand">
            <img src={logo} alt="Company Logo" />
            <span>AR Experts LTD</span>
          </div>
          <h1 className="navbar-heading">Super Admin Dashboard</h1>
          <div className='dropdown' ref={dropdownRef}>
            <button id="dropdown-btn" className='dropdown-btn' onClick={toggleDropdown}><span className="icon"><FontAwesomeIcon icon={faBars} /></span><span className="text">Menu</span></button>
            <div className={`dropdown-content ${dropdownOpen ? 'show' : ''}`}>
            <div className="navbar-links">
            <ul className="navbar-links">
                <li><button className="btn-primary-his" onClick={handleNavigateToHistory}><span className="icon"><FontAwesomeIcon icon={faHistory} /></span>Route History</button></li>
                <li><button className="btn-primary-geo" onClick={handleNavigateToGeofencing}><span className="icon"><FontAwesomeIcon icon={faMapMarkerAlt} /></span><span className="text">Geofencing</span></button></li>
                <li><button className="btn-primary-con" onClick={handleNavigateToContact}><span className="icon"><FontAwesomeIcon icon={faEnvelope} /></span><span className="text">Contact Us</span></button></li>
                <li><button className="btn-primary-logout" onClick={logout}><span className="icon"><FontAwesomeIcon icon={faSignOutAlt} /></span><span className="text">Logout</span></button></li>
              </ul>
          </div>
            </div>
          </div>  
        </div>
      </nav>
      <main className="main-content">
      {!dataLoaded ? (
        <LoadingScreen /> // Show loading screen while data is being fetched
      ) : (        <DeviceList
          vehicleData={vehicleData}
          deviceDetails={deviceDetails}
          showCreateUserForm={showCreateUserForm}
          showAddDeviceForm={showAddDeviceForm}
          showUserList={showUserList}
          createUserFormData={{}} // Not used in DeviceList
          addDeviceFormData={{}} // Not used in DeviceList
          selectedVehicle={selectedVehicle}
          selectedLogic={selectedLogic}
          loading={loading}
          error={error}
          toggleCreateUserForm={() => setShowCreateUserForm(!showCreateUserForm)}
          toggleAddDeviceForm={() => setShowAddDeviceForm(!showAddDeviceForm)}
          handleCreateUserInputChange={() => {}} // Not used in DeviceList
          handleCreateUserCheckboxChange={() => {}} // Not used in DeviceList
          handleCreateUserLogicChange={() => {}} // Not used in DeviceList
          handleCreateUserFormSubmit={handleCreateUserFormSubmit}
          handleCreateGeofenceSubmit={handleCreateGeofenceSubmit}
          closeCreateUserForm={() => setShowCreateUserForm(false)}
          closeAddDeviceForm={() => setShowAddDeviceForm(false)}
          handleAddDeviceInputChange={() => {}} // Not used in DeviceList
          handleAddDeviceFormSubmit={() => {}} // Not used in DeviceList
          flyToVehicle={flyToVehicle}
          handleLogicSelection={handleLogicSelection}
          setShowUserList={setShowUserList}
        />

      )}

        {showCreateUserForm && <CreateUserForm deviceDetails={deviceDetails} onSubmit={handleCreateUserFormSubmit} onClose={() => setShowCreateUserForm(false)} />}
        {showAddDeviceForm && <AddDeviceForm onClose={() => setShowAddDeviceForm(false)} />}
        {showCreateGeofenceForm && (
        <CreateGeofenceForm
          deviceDetails={deviceDetails}
          onSubmit={handleCreateGeofenceSubmit}
          onClose={() => setShowCreateGeofenceForm(false)}
        />
      )}
        <div id="map" ref={mapRef} className="map-container"></div>
      </main>
    </div>
  );
};

export default Home;
