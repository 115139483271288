import React, { useEffect, useRef, useState, useCallback } from "react";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import "./AssignedDevices.scss";
import logo from "../../assets/AR logo.png";
import { Link, useNavigate } from "react-router-dom";
import { BACKEND_URL, logoutUser } from "../../services/authService";
import { SET_LOGIN } from "../../redux/features/auth/authSlice";
import { useDispatch, useSelector } from 'react-redux';
import AddDeviceForm from "../../components/deviceList/AddDevice";
import CreateClientUserForm from "../../components/deviceList/CreateClientUser";
import ClientUserList from "../../components/users/ClientUserList";
import axios from 'axios';
import productService from "../../redux/features/product/productService";
import { selectUser } from '../../redux/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faHistory, faMapMarkerAlt, faEnvelope, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import deliveryVanIconUrl from '../../assets/delivery_van_icon.png';
import LoadingScreen from '../Home/LoadingScreen';

const REACT_APP_FLESPI_TOKEN = process.env.REACT_APP_FLESPI_TOKEN;

const logicTooltips = {
    logic1: "In Logic 1, you will only be able to view the vehicle activity logs",
    logic2: "In Logic 2, you will be able to view the vehicle activity logs and you have access to Lock/Unlock doors remotely along with the Geofence Logs",
    logic3: "In Logic 3, you will be able to view the vehicle activity logs and you have access to Lock/Unlock doors as well as Block/Unblock Engine remotely along with the Geofence Logs",
    logic4: "In Logic 4, you will be able to view the vehicle activity logs and you have access to Lock/Unblock doors, Block/Unblock Engine and CutOff/Enable accelerator remotely along with the Geofence Logs and Vehicle Efficiency(i.e. Fuel Level, Braking Score, Speeding Score and Idling Score)"
};

const AssignedDevices = () => {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const markersRef = useRef([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showCreateUserForm, setShowCreateUserForm] = useState(false);
  const [showAddDeviceForm, setShowAddDeviceForm] = useState(false);
  const [showUserList, setShowUserList] = useState(false);
  const [deviceDetails, setDeviceDetails] = useState({});
  const [deviceData, setDeviceData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");  // New state for search query
  const user = useSelector(selectUser);
  const deviceDetailsRef = useRef({});

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  const fetchAssignedDevices = useCallback(async () => {
    if (!user || !user._id) {
      console.error("User ID is not defined");
      return;
    }

    try {
      console.log("Fetching assigned devices...");
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/${user._id}/devices`);
      console.log("Assigned devices response:", response.data);

      if (Array.isArray(response.data)) {
        setDeviceData(response.data);
      } else {
        console.error("Assigned devices response is not an array:", response.data);
        setDeviceData([]);
      }
    } catch (error) {
      console.error("Error fetching assigned devices:", error);
      setDeviceData([]);
    }
  }, [user]);

  const fetchDeviceDetails = useCallback(async (deviceId) => {
    if (deviceDetailsRef.current[deviceId]) return;

    const maxRetries = 5;
    for (let i = 0; i < maxRetries; i++) {
      try {
        console.log(`Fetching details for device ${deviceId}...`);
        const response = await axios.get(`${BACKEND_URL}/api/flespi/gw/devices/${deviceId}`, {
          headers: {
            'Authorization': `FlespiToken ${REACT_APP_FLESPI_TOKEN}`,
            'Content-Type': 'application/json'
          }
        });
        console.log(`Device details for ${deviceId}:`, response.data);

        if (response.data && response.data.result && response.data.result.length > 0) {
          const deviceData = response.data.result[0];
          deviceDetailsRef.current[deviceId] = deviceData;
          setDeviceDetails({ ...deviceDetailsRef.current });
          return;
        } else {
          console.warn(`No device data found for device ID: ${deviceId}`);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 429) {
            const retryAfter = error.response.headers['retry-after'] ? 
              parseInt(error.response.headers['retry-after']) * 1000 : 
              (2 ** i) * 1000;
            console.warn(`Rate limit exceeded. Retrying after ${retryAfter} ms...`);
            await delay(retryAfter);
          } else {
            console.error('Error fetching device details:', error);
            break;
          }
        } else {
          console.error('Error fetching device details:', error);
          break;
        }
      }
    }
  }, []);

  const fetchTelemetryData = useCallback(async () => {
    if (!deviceData.length) {
      console.warn("No devices to fetch telemetry data for.");
      return;
    }

    let accumulatedVehicleData = [];

    for (const device of deviceData) {
      const deviceString = device[0];
      const deviceId = deviceString.split("/")[1];
      try {
        console.log(`Fetching telemetry data for device ${deviceId}...`);
        const response = await axios.get(`${BACKEND_URL}/api/flespi/gw/devices/${deviceId}/telemetry/position,can.vehicle.mileage,can.engine.rpm,can.fuel.level,can.engine.ignition.status,can.handbrake.status,can.vehicle.speed`, {
          headers: {
            'Authorization': `FlespiToken ${REACT_APP_FLESPI_TOKEN}`,
            'Content-Type': 'application/json',
          },
        });
        console.log(`Telemetry data for ${deviceId}:`, response.data);

        if (response.data && response.data.result && response.data.result.length > 0) {
          const vehicle = response.data.result[0];
          const telemetry = vehicle.telemetry;
          const position = telemetry.position ? telemetry.position.value : null;

          await fetchDeviceDetails(deviceId);

          const deviceData = deviceDetailsRef.current[deviceId];
          const deviceName = deviceData?.name || `Device ${deviceId}`;
          const imei = deviceData?.configuration?.ident;

          if (position) {
            const longitude = position.longitude;
            const latitude = position.latitude;

            if (typeof latitude === 'number' && typeof longitude === 'number' && !isNaN(latitude) && !isNaN(longitude)) {
              // Fetch the address based on latitude and longitude
              const address = await productService.fetchAddresses(latitude, longitude);
              
              const popupContent =
                `<div class="custom-popup">
                    <h4>${deviceName}</h4>
                    <p>IMEI: ${imei}</p>
                    <p>Vehicle ID: ${vehicle.id}</p>
                    <p class="phone-number"><a href="/dashboard">Monitor the vehicle</a></p>
                </div>`;

              const marker = L.marker([latitude, longitude], {
                icon: L.icon({
                  iconUrl: deliveryVanIconUrl,  // Update with actual icon URL
                  iconSize: [30, 40],
                  iconAnchor: [15, 40],
                  popupAnchor: [0, -40],
                }),
              }).addTo(mapInstance.current).bindPopup(popupContent);

              markersRef.current.push(marker);

              accumulatedVehicleData.push({
                longitude,
                latitude,
                address,  // Store the resolved address
                deviceName,
                deviceId: vehicle.id,
                imei,
                telemetry,
              });
            }
          }
        } else {
          console.warn(`No telemetry data found for device ${deviceId}`);
        }
      } catch (error) {
        console.error('Error fetching telemetry data:', error);
      }
    }

    console.log('All vehicle data:', accumulatedVehicleData);
    setVehicleData(accumulatedVehicleData);
    setDataLoaded(true);
  }, [deviceData, fetchDeviceDetails]);

  useEffect(() => {
    fetchAssignedDevices();
  }, [fetchAssignedDevices]);

  useEffect(() => {
    if (mapRef.current && !mapInstance.current) {
      console.log("Initializing map...");
      const initializedMap = L.map(mapRef.current).setView([53.4808, -2.2426], 6);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors'
      }).addTo(initializedMap);
      mapInstance.current = initializedMap;
    }
  }, []);

  useEffect(() => {
    if (deviceData.length > 0 && mapInstance.current) {
      console.log("Fetching telemetry data after devices loaded...");
      fetchTelemetryData();
    }
  }, [deviceData, fetchTelemetryData]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const flyToDevice = (longitude, latitude) => {
    if (mapInstance.current) {
      if (longitude !== undefined && latitude !== undefined) {
        console.log(`Flying to location: ${latitude}, ${longitude}`);
        mapInstance.current.flyTo([latitude, longitude], 14, { duration: 3 });
      } else {
        console.error("Invalid location data for vehicle:", { longitude, latitude });
      }
    }
  };

  const handleLogicSelection = (deviceDetail, logic) => {
    if (deviceDetail && deviceDetail.configuration && deviceDetail.configuration.ident) {
      const imei = deviceDetail.configuration.ident;
      switch (logic) {
        case 'logic1':
          navigate(`/logiconedashboard/${imei}`, { state: { from: '/assigned-devices' } });
          break;
        case 'logic2':
          navigate(`/logictwodashboard/${imei}`, { state: { from: '/assigned-devices' } });
          break;
        case 'logic3':
          navigate(`/logicthreedashboard/${imei}`, { state: { from: '/assigned-devices' } });
          break;
        case 'logic4':
        default:
          navigate(`/dashboard/${imei}`, { state: { from: '/assigned-devices' } });
          break;
      }
    }
  };

  const handleNavigateToHistory = () => {
    navigate('/data-and-history', { state: { from: '/assigned-devices' } });
  };

  const handleNavigateToGeofencing = () => {
    navigate('/geofencing', { state: { from: '/assigned-devices' } });
  };

  const handleNavigateToContact = () => {
    navigate('/contact-us', { state: { from: '/devices' } });
  };

  const logout = async () => {
    await logoutUser();
    await dispatch(SET_LOGIN(false));
    navigate("/");
  };

  const handleCreateUserSubmit = async (formData) => {
    try {
      await axios.post(`${BACKEND_URL}/api/users/create-client-user`, formData);
      alert("User created successfully!");
      setShowCreateUserForm(false);
    } catch (error) {
      console.error("Error creating user:", error);
      alert("Failed to create user.");
    }
  };

  const handleAddDeviceSubmit = async (formData) => {
    try {
      await axios.post(`${BACKEND_URL}/api/devices`, formData);
      alert("Device added successfully!");
      setShowAddDeviceForm(false);
    } catch (error) {
      console.error("Error adding device:", error);
      alert("Failed to add device.");
    }
  };

  const handleUsersClick = () => {
    setShowUserList(true);
  };

  const handleCreateUserClick = async () => {
    setShowCreateUserForm(true);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter devices based on the search query
  const filteredDeviceData = deviceData.filter(device => {
    const deviceId = device[0];
    const deviceDetail = deviceDetails[deviceId];
    const deviceName = deviceDetail?.name?.toLowerCase() || '';
    return deviceName.includes(searchQuery.toLowerCase());
  });

  return (
    <div className="userHome">
      <nav className="navbar">
        <div className="container --flex-between">
          <div className="navbar-brand">
            <img src={logo} alt="Company Logo" />
            <span>AR Experts LTD</span>
          </div>
          <h1 className="navbar-heading">Admin Dashboard</h1>
          <div className='dropdown'>
            <button id="dropdown-btn" className='dropdown-btn' onClick={toggleDropdown}><span className="icon"><FontAwesomeIcon icon={faBars} /></span><span className="text">Menu</span></button>
            <div className={`dropdown-content ${dropdownOpen ? 'show' : ''}`}>
              <ul className="navbar-links">
                <li><button className="btn-primary-his" onClick={handleNavigateToHistory}><span className="icon"><FontAwesomeIcon icon={faHistory} /></span>Route History</button></li>
                <li><button className="btn-primary-geo" onClick={handleNavigateToGeofencing}><span className="icon"><FontAwesomeIcon icon={faMapMarkerAlt} /></span><span className="text">Geofencing</span></button></li>
                <li><button className="btn-primary-con" onClick={handleNavigateToContact}><span className="icon"><FontAwesomeIcon icon={faEnvelope} /></span><span className="text">Contact Us</span></button></li>
                <li><button className="btn-primary-logout" onClick={logout}><span className="icon"><FontAwesomeIcon icon={faSignOutAlt} /></span><span className="text">Logout</span></button></li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <main className="userContent">
      {!dataLoaded ? (  // If data is not yet loaded, show the loading screen
    <LoadingScreen />
  ) : (
        <div className="store-listt">
          <div className="heading">
            <h2>List of Devices</h2>
          </div>
            <div className='search-container'>
              <input
                type="text"
                placeholder="Search devices by name"
                value={searchQuery}
                onChange={handleSearchChange}
                className="device-search-input"
              />
              <i className="fas fa-search search-icon"></i>
              <button className="btn-primary-customm create" onClick={handleCreateUserClick}><i className="fas fa-user-plus"></i></button>
            </div>
            <button className="btn-primary-customm addDevv" onClick={() => setShowAddDeviceForm(true)}>Add Device</button>
            <button className="btn-primary-customm setupp" onClick={handleUsersClick}>Users</button>

            {showCreateUserForm && (
              <CreateClientUserForm
                userId={user._id}
                deviceDetails={deviceDetails}
                onSubmit={handleCreateUserSubmit}
                onClose={() => setShowCreateUserForm(false)}
              />
            )}
            {showAddDeviceForm && (
              <AddDeviceForm
                onSubmit={handleAddDeviceSubmit}
                onClose={() => setShowAddDeviceForm(false)}
              />
            )}
            {showUserList && (
              <ClientUserList onClose={() => setShowUserList(false)} />
            )}
            <ul className="listt">
              {filteredDeviceData.map((device, index) => {
                const deviceId = device[0].split("/")[1];
                const deviceDetail = deviceDetails[deviceId];
                if (!deviceDetail) return null;
                const { name, configuration } = deviceDetail;
                const imei = configuration?.ident || 'Unavailable';
                const assignedLogics = device.assignedLogics || [];  // Assigned logics for the device

                const telemetry = vehicleData.find(v => v.imei === imei);
                const address = telemetry ? telemetry.address : 'Fetching address...';

                return (
                  <li key={index}>
                    <div className="shop-items">
                      <Link to="#" className="link-button" onClick={() => flyToDevice(telemetry.longitude, telemetry.latitude)}>{name || 'Unknown'}</Link>
                      <p>IMEI: {imei}</p>
                      <p><strong>Location:</strong> {address}</p>
                      <p><strong>{`${assignedLogics.join(', ')} has been assigned`}</strong></p>
                      <div className="logic-selections">
                        <select
                          className="selectt"
                          onChange={(e) => handleLogicSelection(deviceDetail, e.target.value)}
                        >
                          <option value="">Select a logic</option>
                          {['logic1', 'logic2', 'logic3', 'logic4'].map((logic, index) => (
                            <option
                              key={index}
                              value={logic}
                              title={!assignedLogics.includes(logic) ? logicTooltips[logic] : ''}
                              disabled={!assignedLogics.includes(logic)}
                            >
                              {logic}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
        </div>
  )}
        <div className="map-containerr">
          <div id="map" ref={mapRef}></div>
        </div>
      </main>
    </div>
  );
};

export default AssignedDevices;